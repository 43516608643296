
import React from 'react'

import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import queryString from 'query-string'

import { Button } from '../components/Button'
import ErrorBox from '../components/ErrorBox'
import { Form, FormItem } from '../components/Form'
import { Input } from '../components/Input'
import { NoAuthLayout } from '../components/LayoutContainer'
import SEO from '../components/SEO'

import { resetPassword } from '../services/strapi/api'

import { useForm } from '../hooks/useForm'

const ResetPasswordForm = () => {
  const [form] = useForm()
  const [error, setError] = React.useState()
  const [isBusy, setBusy] = React.useState(false)
  const [isDone, setDone] = React.useState(false)

  const location = useLocation()
  const query = queryString.parse(location.search || '')

  const code = query.code

  const onFinish = values => {
    setError(null)
    setBusy(true)

    const { password, passwordConfirmation } = values
    resetPassword({ code, password, passwordConfirmation }).then(res => {
      setBusy(false)

      if (res.status === 200) {
        setDone(true)
      } else if (res.error) {
        setError(res.error)
      } else {
        setError({ error: 'Failed to reset password.' })
      }
    })
  }

  if (isDone) {
    return (
      <p>
        {`You may now `}
        <Link to="/">Log In</Link>
        {` with your new password.`}
      </p>
    )
  }

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <FormItem name="password" required>
          <Input.Password placeholder="Password" />
        </FormItem>

        <FormItem name="passwordConfirmation" required>
          <Input.Password placeholder="Confirm Password" />
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isBusy}
            disabled={isBusy}
          >
            Submit
          </Button>
        </FormItem>

        {error && <ErrorBox errors={error} />}
      </Form>
    </div>
  )
}

const ResetPasswordPage = () => (
  <NoAuthLayout>
    <SEO title="Reset Password" />
    <div className="content">
      <div style={{ margin: '3rem auto', maxWidth: 600 }}>
        <h2>Reset Password</h2>
        <ResetPasswordForm />
      </div>
    </div>
  </NoAuthLayout>
)

export default ResetPasswordPage
